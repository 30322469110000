<template>
  <a-card title="操作记录">
    <!--<a type="primary" slot="extra" @click="moreOpen"
       style="margin-left: 15px;">更多
    </a>-->
    <a-list :dataSource="listData" :pagination="pagination"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <a-avatar slot="avatar">{{item.userName}}</a-avatar>
          <!--<a-avatar class="avatar" :size="64" v-show="!item.headImg">{{item.userName}}</a-avatar>-->
          <!--<a-avatar class="avatar" :size="64" v-show="item.headImg" src="http://localhost:9906/upload/20191106/_6c2c5bf5875c425c81c5525820562fb5.jpg"></a-avatar>-->
          <div slot="title" style="margin-top: 5px;">
            <span>{{item.userName}}</span>
            <span>{{item.operate}}了</span>
            <span>{{(appModuleType.find(x=> x.value === item.appModule)).name}}</span>
            <span style="display: block;float: right;">{{item.dateCreated|transformTime}}前</span>
          </div>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </a-card>
</template>

<script>

  import SERVICE_URLS from '../../api/service.url'
  import moment from 'moment'
  import AListItem from 'ant-design-vue/es/list/Item'
  import { appModuleType } from '../../api/vo/base/appModuleVO'

  export default {
    name: 'SubOperator',
    components: { AListItem },
    data () {
      return {
        loading: true,
        busy: false,
        operatorData: [],
        searchParams: {
          number: '1',
          idUser: '',
          size: '10',
          direction: 'DESC',
          order: 'dateCreated'
        },
        listData: [],
        appModuleType: appModuleType(),
        /*分页*/
        pagination: {
          onChange: (page) => {
            this.loading = true
            this.searchParams.number = page
            this.loadData()
          },
          total: 0,
          simple: true,
          pageSize: 10,
          showSizeChanger: true,
        },
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      },
    },
    created () {
      this.searchParams.idUser = this.currentUser.id
      this.loadData()
    },
    filters: {
      transformTime (time) {
        return moment(time).fromNow(true)
      },
    },
    methods: {
      onChange (page, pageSize) {
        this.searchParams.number = page
        this.loadData()
      },
      tableChange (pagination, filters, sorter) {
        this.searchParams.size = pagination.pageSize
        this.searchParams.number = pagination.current - 1
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.search()
      },
      loadData () {
        this.$http(this, {
          url: SERVICE_URLS.platBase.operationRecord.search,
          data: this.searchParams,
          noTips: 'true',
          success: (data) => {
            this.listData = data.body.content
            this.pagination.total = data.body.totalElements
          }
        })
      },
    }
  }
</script>