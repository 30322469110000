<template>
  <a-card title="快捷工具" style="margin-bottom: 24px" :bordered="false" :body-style="{padding: 0}">
    <a-button size="small" slot="extra" @click="clickButton" type="primary" :loading="loading" ghost>
      <span v-if="!showSave">编辑</span>
      <span v-else>保存</span>
    </a-button>
    <div class="item-group">
      <div v-for="item in userItems" style="display: inline-block">
        <a-tag @click="handlePush(item)" :color="colorArr[Math.floor(Math.random()*7)]" style="margin-top: 8px">
          <span>{{item.meta.title}}</span>
        </a-tag>
        <a-icon style="padding-right: 10px;padding-left: 0;" v-if="showClose" type="close-circle"
                twoToneColor="#eb2f96" theme="twoTone"
                @click="delUserMenu(item)"/>
      </div>
    </div>
    <a-select
      v-if="selectVisible" @change="handleChange"
      style="width:100%;padding: 0 15px 15px;"
      placeholder="请选择快捷入口">
      <a-select-option
        v-for="item in childrenItem"
        :key="item.key"
        :value="item.key"
        :label="item.meta.title">
        {{ item.meta.title }}
      </a-select-option>
    </a-select>
  </a-card>
</template>

<script>
  import SERVICE_URLS from '../../api/service.url'

  export default {
    name: 'SubUserMenu',
    data () {
      return {
        loading: false,
        selectVisible: false,
        editEvent: false,
        showClose: false,
        childrenItem: [],
        userItems: [],
        showSave: false,
        resourceIds: [],
        commitResourceIds: [], //提交的资源IDS
        userId: 0,
        itemList: [],
        colorArr: [ 'pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple' ]
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      },
      columnItems () {
        return this.$store.getters.columnItems
      }
    },
    mounted () {
      this.loadList()
    },
    created () {
      this.userId = eval(this.currentUser.id)
      // console.log(this.columnItems)
      // let columnItems = this.columnItems
      // if (!columnItems.length) {
      for (let item of this.columnItems) {
        let arr = item.children
        if (arr) {
          for (let subItem of arr) {
            this.childrenItem.push(subItem)
          }
        }
      }
      // }
    },
    methods: {
      handleChange (value) {
        /*遍历所有子菜单栏目*/
        for (let item of this.childrenItem) {
          if (item.key === value && this.resourceIds.indexOf(eval(value)) === -1) {
            this.userItems.push(item)
            this.resourceIds.push(item.key)
          }
        }
      },
      handlePush (item) {
        this.$router.push(
          {
            path: item.path
          }
        )
      },
      clickButton () {
        if (this.showSave) {
          this.saveSubmit()
        } else {
          this.selectVisible = true
          this.showClose = true
          this.showSave = !this.showSave
        }
      },
      saveSubmit () {
        this.loading = true
        this.commitResourceIds = []
        for (let item of this.userItems) {
          this.commitResourceIds.push(eval(item.key))
        }
        this.$http(this, {
          url: SERVICE_URLS.user.menu.update,
          data: {
            resourceIds: this.commitResourceIds,
            userId: this.userId
          },
          noTips: 'true',
          success: (data) => {
            this.$message.success('保存成功！')
            this.showSave = !this.showSave
            this.selectVisible = false
            this.showClose = false
            this.loading = false
          },
          error: (data) => {
            this.loading = false
          }
        })
      },
      delUserMenu (item) {
        this.$http(this, {
          url: SERVICE_URLS.user.menu.delete,
          params: {
            id: item.id
          },
          noTips: 'true',
          success: (data) => {
            this.$message.success('删除成功！')
            this.loadList()
          }
        })
      },
      loadList () {
        this.userItems = []
        this.resourceIds = []
        this.$http(this, {
          url: SERVICE_URLS.user.menu.list,
          data: {
            userId: this.userId
          },
          noTips: 'true',
          success: (data) => {
            for (let listItem of data.body) {
              this.resourceIds.push(listItem.resource.id)
              this.userItems.push({
                meta: { title: listItem.resource.name },
                path: listItem.resource.urls,
                key: listItem.resource.id,
                id: listItem.id
              })
            }
          }
        })
      }
    }
  }
</script>

<style scoped>
  .item-group {
    padding: 7px 15px 15px;
  }

  .item-group a {
    padding-left: 10px;
    padding-right: 0px;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    line-height: 40px;
  }
</style>